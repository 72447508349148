import React from 'react';
import { motion } from 'framer-motion';

const Home = () => {
  return (
    <div className="container mx-auto px-4 py-20">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{opacity: 0, x: 100}}
        transition={{ duration: 1 }}
        className="text-center"
      >
        <h1 className="text-5xl font-bold mb-4">Welcome to Creative Production Studio</h1>
        <p className="text-xl text-gray-700">
          We bring your visions to life with exceptional film production services.
        </p>
      </motion.div>
    </div>
  );
};

export default Home;
