import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

const Navbar = () => {
  const navItems = [
    { name: 'Home', path: '/' },
    { name: 'Our Services', path: '/services' },
    { name: 'About', path: '/about' },
    { name: 'Contact', path: '/contact' },
  ];

  return (
    <nav className="bg-white text-black">
      <div className="container mx-auto px-4 py-6 flex flex-col items-center">
        <Link to="/" className="text-4xl md:text-6xl font-bold font-cinzel text-center mb-2">
          Creative Production Studio
        </Link>
        <div className="w-full max-w-[40%] md:max-w-[90%] flex justify-between mt-6">
          {navItems.map((item) => (
            <NavLink
              key={item.name}
              to={item.path}
              className={({ isActive }) =>
                isActive
                  ? 'text-blue-500'
                  : 'text-black hover:text-blue-500 transition duration-300'
              }
            >
              <motion.span
                whileHover={{
                  scale: 1.1,
                  filter: 'drop-shadow(0px 4px 6px rgba(128, 128, 128, 0.5))',
                }}
                className="font-sans inline-block"
              >
                {item.name}
              </motion.span>
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
