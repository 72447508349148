// src/pages/OurServices/OurServices.tsx

import React from "react";
import { motion } from "framer-motion";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import videographyImg from "../../assets/images/videography.jpg";
import vfxImg from "../../assets/images/vfx.jpg";
import writersImg from "../../assets/images/writers.jpg";
import filmMakersImg from "../../assets/images/filmmakers.jpg";

const headingVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, x: 100 },
};

const cardsContainerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.5, // Adjusted stagger delay
      delayChildren: 0.2, // Initial delay before cards start animating
    },
  },
  exit: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: -1, // Exit in reverse order
    },
  },
};

const cardVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, x: 100 },
};

const OurServices: React.FC = () => {
  const services = [
    {
      image: videographyImg,
      title: "Videography Team",
      description:
        "Our expert videographers capture every moment with precision and creativity.",
      link: "#",
    },
    {
      image: vfxImg,
      title: "VFX Team",
      description:
        "Enhance your films with stunning visual effects crafted by our VFX specialists.",
      link: "#",
    },
    {
      image: writersImg,
      title: "Writers",
      description:
        "Our talented writers develop compelling scripts that resonate with audiences.",
      link: "#",
    },
    {
      image: filmMakersImg,
      title: "Film Makers",
      description:
        "From concept to final cut, our filmmakers ensure your story is told beautifully.",
      link: "#",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-20">
            {/* Heading Animation */}
            
      <motion.h2
        className="text-4xl font-bold text-center mb-12"
        variants={headingVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 1 }}
      >
                Our Services       
      </motion.h2>
            {/* Cards Container with Staggered Animations */}
            
      <motion.div
        className="space-y-6"
        variants={cardsContainerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
                
        {services.map((service, index) => (
          <motion.div
            key={index}
            variants={cardVariants}
            transition={{ duration: 1 }} // Duration of each card's animation
          >
                        
            <ServiceCard
              image={service.image}
              title={service.title}
              description={service.description}
              link={service.link}
            />
                      
          </motion.div>
        ))}
              
      </motion.div>
          
    </div>
  );
};

export default OurServices;
