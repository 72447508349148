import React from 'react';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <div className="container mx-auto px-4 py-20">
      <motion.div
        className="max-w-3xl mx-auto text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2 className="text-4xl font-bold mb-4">About Us</h2>
        <p className="text-gray-700">
          Creative Production Studio is a new film production company dedicated to
          delivering high-quality cinematic experiences. Our team of passionate
          professionals works collaboratively to bring your stories to life.
        </p>
      </motion.div>
    </div>
  );
};

export default About;
