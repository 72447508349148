// src/pages/Contact/Contact.tsx

import React from 'react';
import { motion } from 'framer-motion';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useForm, Controller } from 'react-hook-form';

interface FormData {
  name: string;
  email: string;
  phone: string;
  country: { label: string; value: string } | null;
  enquirySubject: { label: string; value: string } | null;
  description: string;
}

const Contact: React.FC = () => {
  const { control, handleSubmit, reset, formState: { errors } } = useForm<FormData>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      country: { label: 'Australia', value: 'Australia' },
      enquirySubject: null,
      description: '',
    },
  });

  const optionsEnquirySubject = [
    { value: 'Videography', label: 'Videography' },
    { value: 'VFX', label: 'VFX' },
    { value: 'Writers', label: 'Writers' },
    { value: 'Film Making', label: 'Film Making' },
    { value: 'General', label: 'General' },
  ];

  const countryOptions = countryList().getData();

  const handleChange = (data: FormData) => {
    console.log(data);
    reset();
    // Provide feedback to the user (e.g., success message)
  };

  return (
    <div className="container mx-auto px-4 py-20">
      <h2 className="text-4xl font-bold text-center mb-8">Contact Us</h2>
      <motion.form
        onSubmit={handleSubmit(handleChange)}
        className="max-w-2xl mx-auto bg-white p-6 rounded shadow-lg"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        {/* Name Field */}
        <div className="mb-1">
          <label className="block text-gray-700 font-bold">Name</label>
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <input
                type="text"
                {...field}
                className={`w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  errors.name ? 'border-red-500' : 'border-gray-300'
                }`}
                placeholder="Your Name"
              />
            )}
          />
          {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>}
        </div>

        {/* Email Field */}
        <div className="mb-1">
          <label className="block text-gray-700 font-bold">Email</label>
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Invalid email address',
              },
            }}
            render={({ field }) => (
              <input
                type="email"
                {...field}
                className={`w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  errors.email ? 'border-red-500' : 'border-gray-300'
                }`}
                placeholder="you@example.com"
              />
            )}
          />
          {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
        </div>

        {/* Phone Field */}
        <div className="mb-1">
          <label className="block text-gray-700 font-bold">Phone</label>
          <Controller
            name="phone"
            control={control}
            rules={{ required: 'Phone number is required' }}
            render={({ field }) => (
              <PhoneInput
                country={'au'}
                value={field.value}
                onChange={field.onChange}
                inputStyle={{
                  width: '100%',
                  height: '40px',
                  borderRadius: '0.375rem', // Tailwind's rounded-lg
                  border: '1px solid #D1D5DB', // Tailwind's border-gray-300
                }}
                containerStyle={{ marginTop: '0.25rem' }}
                buttonStyle={{
                  border: '1px solid #D1D5DB',
                  borderRadius: '0.375rem',
                }}
                inputProps={{
                  name: 'phone',
                  required: true,
                  className: 'focus:outline-none focus:ring-2 focus:ring-blue-500',
                  placeholder: '1234567890',
                }}
              />
            )}
          />
          {/* You can add error messages if needed */}
        </div>

        {/* Country Selector */}
        <div className="mb-1">
          <label className="block text-gray-700 font-bold">Country</label>
          <Controller
            name="country"
            control={control}
            rules={{ required: 'Country is required' }}
            render={({ field }) => (
              <Select
                {...field}
                options={countryOptions}
                defaultValue={{ label: 'Australia', value: 'Australia' }}
                classNamePrefix="react-select"
                placeholder="Select Country"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    marginTop: '0.25rem',
                    borderColor: state.isFocused
                      ? '#3B82F6' // Tailwind's blue-500
                      : '#D1D5DB', // Tailwind's gray-300
                    boxShadow: state.isFocused ? '0 0 0 2px rgba(59, 130, 246, 0.5)' : provided.boxShadow,
                    '&:hover': {
                      borderColor: '#3B82F6',
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '200px', // Approximately 10 options
                    overflowY: 'auto'
                  }),
                }}
                isClearable
              />
            )}
          />
          {errors.country && <p className="text-red-500 text-sm mt-1">{errors.country.message}</p>}
        </div>

        {/* Enquiry Subject Field */}
        <div className="mb-1">
          <label className="block text-gray-700 font-bold">Enquiry Subject</label>
          <Controller
            name="enquirySubject"
            control={control}
            rules={{ required: 'Enquiry subject is required' }}
            render={({ field }) => (
              <Select
                {...field}
                options={optionsEnquirySubject}
                placeholder="Select Subject"
                classNamePrefix="react-select"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    marginTop: '0.25rem',
                    borderColor: state.isFocused
                      ? '#3B82F6' // Tailwind's blue-500
                      : '#D1D5DB', // Tailwind's gray-300
                    boxShadow: state.isFocused ? '0 0 0 2px rgba(59, 130, 246, 0.5)' : provided.boxShadow,
                    '&:hover': {
                      borderColor: '#3B82F6',
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '200px', // Approximately 10 options
                  }),
                }}
              />
            )}
          />
          {errors.enquirySubject && (
            <p className="text-red-500 text-sm mt-1">{errors.enquirySubject.message}</p>
          )}
        </div>

        {/* Description Field */}
        <div className="mb-1">
          <label className="block text-gray-700 font-bold">Description</label>
          <Controller
            name="description"
            control={control}
            rules={{ required: 'Description is required' }}
            render={({ field }) => (
              <textarea
                {...field}
                className={`w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  errors.description ? 'border-red-500' : 'border-gray-300'
                }`}
                rows={5}
                placeholder="Describe your enquiry..."
              ></textarea>
            )}
          />
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">{errors.description.message}</p>
          )}
        </div>

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
          >
            Submit
          </button>
        </div>
      </motion.form>
    </div>
  );
};

export default Contact;