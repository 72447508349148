import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

interface ServiceCardProps {
  image: string;
  title: string;
  description: string;
  link: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  image,
  title,
  description,
  link,
}) => {
  return (
    <motion.div
      className="flex flex-col md:flex-row bg-white rounded-lg overflow-hidden mb-6 shadow-glow hover:shadow-glow"
      whileHover={{ scale: 1.1 }}
      transition={{duration: 0.3}}
    >
      <div className="md:w-1/3">
        <img src={image} alt={title} className="w-full h-full object-cover" />
      </div>
      <div className="md:w-2/3 p-6 flex flex-col justify-between">
        <div>
          <h3 className="text-2xl font-semibold mb-2">{title}</h3>
          <p className="text-gray-700">{description}</p>
        </div>
        <div className="mt-4 text-right">
          <Link
            to={link}
            className="text-blue-500 hover:underline transition duration-300"
          >
            Know More &rarr;
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default ServiceCard;